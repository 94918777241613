import type { OrderNumber } from '@deepup/apis/dist/typescript/organization-management/v1/organization_management';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, type GridColDef } from '@mui/x-data-grid';
import { useFlags } from 'flagsmith/react';
import type { SnackbarOrigin } from 'notistack';
import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { DeleteDialog } from '@components/DeleteDialog/DeleteDialog';
import { FormSectionHeader } from '@components/FormElements';
import { EmptyTableInfo } from '@components/PageEntityTable/EmptyTableInfo';
import { SMANumberCloseDialog } from '@components/SMANumberCloseDialog';
import { renderCellContent } from '@components/TableCell/TableCell';
import { useProjectApiExportUpload } from '@hooks/api/projects';
import { useCloseSMANumberApi } from '@hooks/api/smaNumbers/useCloseSMANumberApi';
import { useDeleteSMANumberApi } from '@hooks/api/smaNumbers/useDeleteSMANumberApi';
import { useSMANumbersApi } from '@hooks/api/smaNumbers/useSMANumbersApi';
import { usePlatformPermissions } from '@hooks/usePlatformPermissions';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { GroupsContextMenu } from '@pages/Groups/GroupsOverview/GroupsContextMenu';

import { ButtonDownloadCsvTemplateFile } from '../ProjectsForm/ProjectsFormSections/ProjectMappingApiExport/ButtonDownloadCsvTemplateFile';
import { ButtonOpenUploadDialog } from '../ProjectsForm/ProjectsFormSections/ProjectMappingApiExport/ButtonOpenUploadDialog';
import { ProjectMappingUploadDialog } from '../ProjectsForm/ProjectsFormSections/ProjectMappingApiExport/ProjectMappingUploadDialog';

import { SMANumberFilters } from './components/SMANumberFilters';
import { useSMANumberFilters } from './hooks/useSMANumberFilters';

const ANCHOR_ORIGIN: SnackbarOrigin = { vertical: 'top', horizontal: 'center' };

export const SMANumberList: FC = () => {
  const { t } = useTranslation();
  const uploadAssetToProject = useProjectApiExportUpload();
  const { id } = useParams();
  const { data, isLoading, mutate: revalidate } = useSMANumbersApi(id);
  const entities = data?.items || [];

  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [file, setFile] = useState<File>();

  const uploadApiExportData = async (uploadedFile: File) => {
    if (uploadedFile && id) {
      try {
        await uploadAssetToProject(uploadedFile, id);
        showMessage({
          message: t('pages.projects.create.messages.uploadAssetSuccess', {
            fileName: uploadedFile.name,
          }),
          type: 'success',
        });
        revalidate();
      } catch (error) {
        showMessage({ message: (error as Error).toString(), type: 'error' });
      }
    }
  };

  const handleCancelUploadDialog = () => {
    setShowUploadDialog(false);
  };

  const handleOpenUploadDialog = () => {
    setShowUploadDialog(true);
  };

  const handleSubmitUploadDialog = (uploadedFile: File) => {
    setFile(uploadedFile);
    setShowUploadDialog(false);
    uploadApiExportData(uploadedFile);
  };

  const deleteSMANumberApi = useDeleteSMANumberApi();
  const closeSMANumberApi = useCloseSMANumberApi(id);
  const { showMessage } = useSnackbarMessage();
  const { isPlatformAdmin } = usePlatformPermissions();

  const [selectedSMANumber, setSelectedSMANumber] = useState<OrderNumber | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [strictDeleteDialogOpen, setStrictDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { sma_number_delete_enabled } = useFlags(['sma_number_delete_enabled']);
  const isDeleteEnabled = sma_number_delete_enabled.enabled;

  const {
    orderNumberFilter,
    setOrderNumberFilter,
    statusFilter,
    setStatusFilter,
    filteredEntities,
  } = useSMANumberFilters(entities);

  const handleDelete = async () => {
    if (!selectedSMANumber) return;

    try {
      setIsDeleting(true);
      await deleteSMANumberApi(selectedSMANumber.id);
      showMessage({
        message: t('pages.smaNumbers.delete.dialog.success', {
          smaNumber: selectedSMANumber.orderNumber,
        }),
        type: 'success',
        anchorOrigin: ANCHOR_ORIGIN,
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      showMessage({
        message: `${t('pages.smaNumbers.delete.dialog.errorTitle', { smaNumber: selectedSMANumber.orderNumber })}
                 \n${t('pages.smaNumbers.delete.dialog.errorContent', { smaNumber: selectedSMANumber.orderNumber })}`,
        type: 'error',
        anchorOrigin: ANCHOR_ORIGIN,
        autoHideDuration: 10000,
      });
    } finally {
      setIsDeleting(false);
      setDeleteDialogOpen(false);
      setSelectedSMANumber(null);
      revalidate();
    }
  };

  const handleCloseSMANumber = async () => {
    if (!selectedSMANumber) return;

    try {
      setIsDeleting(true);
      await closeSMANumberApi(selectedSMANumber.id);
      showMessage({
        message: t('pages.smaNumbers.edit.dialog.success', {
          smaNumber: selectedSMANumber.orderNumber,
        }),
        type: 'success',
        anchorOrigin: ANCHOR_ORIGIN,
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      showMessage({
        message: `${t('pages.smaNumbers.edit.dialog.errorTitle', { smaNumber: selectedSMANumber.orderNumber })}
                 \n${t('pages.smaNumbers.edit.dialog.errorContent', { smaNumber: selectedSMANumber.orderNumber })}`,
        type: 'error',
        anchorOrigin: ANCHOR_ORIGIN,
        autoHideDuration: 10000,
      });
    } finally {
      setIsDeleting(false);
      setStrictDeleteDialogOpen(false);
      setSelectedSMANumber(null);
      revalidate();
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'orderNumber',
      headerName: t('pages.smaNumbers.columns.orderNumber'),
      flex: 1,
    },
    {
      field: 'type',
      headerName: t('pages.smaNumbers.columns.type'),
      flex: 1,
      renderCell: (params) => params.row.type.name,
      sortComparator: (type1, type2) => type1.name.localeCompare(type2.name),
    },
    {
      field: 'closedAt',
      headerName: t('pages.smaNumbers.columns.status'),
      flex: 1,
      renderCell: (params) =>
        params.row.closedAt
          ? t('pages.smaNumbers.status.closed')
          : t('pages.smaNumbers.status.open'),
    },
    {
      field: 'context-menu',
      headerName: '',
      width: 50,
      renderCell: (params) =>
        renderCellContent({
          entityType: 'groups',
          params,
          isLoading,
          columnField: 'context-menu',
          renderContent: () => {
            if (params.row.closedAt) return null;

            return (
              <GroupsContextMenu
                editLabel={t('pages.smaNumbers.edit.contextMenu')}
                groupId={params.row.id}
                groupName={params.row.name}
                hasDelete={isPlatformAdmin && isDeleteEnabled}
                hasEdit
                onClickDelete={() => {
                  setSelectedSMANumber(params.row);
                  setDeleteDialogOpen(true);
                }}
                onClickEdit={() => {
                  setSelectedSMANumber(params.row);
                  setStrictDeleteDialogOpen(true);
                }}
              />
            );
          },
        }),
      sortable: false,
      filterable: false,
    },
  ];

  return (
    <Box marginBottom={4}>
      <Stack gap={2} mb={2}>
        <Stack direction="row" flexWrap="wrap" gap={2} mt={2}>
          <ButtonDownloadCsvTemplateFile disabled={false} />
          <ButtonOpenUploadDialog disabled={false} file={file} onClick={handleOpenUploadDialog} />
        </Stack>

        <ProjectMappingUploadDialog
          disabled={false}
          file={file}
          onCancel={handleCancelUploadDialog}
          onSubmit={handleSubmitUploadDialog}
          open={showUploadDialog}
        />
      </Stack>

      <FormSectionHeader title={t('pages.smaNumbers.title')} />

      <Box display="flex" flexDirection="column" minHeight={600}>
        <>
          <SMANumberFilters
            disabled={isLoading || entities.length === 0}
            onOrderNumberChange={setOrderNumberFilter}
            onStatusChange={setStatusFilter}
            orderNumberFilter={orderNumberFilter}
            statusFilter={statusFilter}
          />
          <DataGrid
            autoHeight
            columns={columns}
            disableColumnMenu
            disableRowSelectionOnClick
            loading={isLoading}
            rows={filteredEntities}
            slotProps={{
              loadingOverlay: {
                variant: 'skeleton',
                noRowsVariant: 'skeleton',
              },
            }}
            slots={{
              noRowsOverlay: () => (
                <Box
                  alignItems="center"
                  display="flex"
                  height="100%"
                  justifyContent="center"
                  minHeight={400}
                >
                  <EmptyTableInfo info={t('pages.smaNumbers.noSMANumbersUploaded')} />
                </Box>
              ),
            }}
          />
        </>

        {selectedSMANumber && (
          <>
            <DeleteDialog
              isDeletingActive={isDeleting}
              message={t('pages.smaNumbers.delete.dialog.content')}
              onClick={(confirmed) => {
                if (confirmed) {
                  handleDelete();
                } else {
                  setDeleteDialogOpen(false);
                  setSelectedSMANumber(null);
                }
              }}
              open={deleteDialogOpen}
              title={t('pages.smaNumbers.delete.dialog.title', {
                smaNumber: selectedSMANumber.orderNumber,
              })}
            />
            <SMANumberCloseDialog
              isDeletingActive={isDeleting}
              onClick={(confirmed) => {
                if (confirmed) {
                  handleCloseSMANumber();
                } else {
                  setStrictDeleteDialogOpen(false);
                  setSelectedSMANumber(null);
                }
              }}
              open={strictDeleteDialogOpen}
              selectedSMANumber={selectedSMANumber.orderNumber}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
