import { useCallback } from 'react';

import { useEnvironment } from '@hooks/useEnvironment';

import { useJsonFetch } from '../useJsonFetch';

export const useDeleteSMANumberApi = () => {
  const { apiUrl } = useEnvironment();
  const jsonFetch = useJsonFetch(true, apiUrl);

  const fetcher = useCallback(
    (orderNumberId: string) =>
      jsonFetch(`/internal-api/ordermeta/v1/ordermeta/${orderNumberId}`, {
        method: 'DELETE',
      }),
    [jsonFetch],
  );

  return fetcher;
};
