import { PencilOutline } from '@deepup/icons';
import { useTranslation } from 'react-i18next';

import { ContextMenuItem, type ContextMenuItemProps } from './ContextMenuItem';

export const ContextMenuEditItem = ({
  onClick,
  label,
}: Pick<ContextMenuItemProps, 'onClick'> & { label?: string }) => {
  const { t } = useTranslation();

  return (
    <ContextMenuItem
      data-testid="context-menu-item-edit"
      icon={<PencilOutline fill="currentColor" height={24} width={24} />}
      label={label || t('common.edit')}
      onClick={onClick}
    />
  );
};
