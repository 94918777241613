import { DocumentTextOutline, FolderOutline, GraphOutline, PersonIdOutline } from '@deepup/icons';
import { CircularProgress, Stack, Tab, Tabs, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useFlags } from 'flagsmith/react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { usePlatformPermissions } from '@hooks/usePlatformPermissions';
import { DetailPageLayout } from '@pages/common';

import { useFormOrganizationState } from './useFormOrganizationState';

export const OrganizationsEdit: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const { organization, isLoading: isLoadingOrganization } = useFormOrganizationState();
  const featureFlags = useFlags(['billing_enabled']);
  const { isAdminOfOrg } = usePlatformPermissions();

  const EDIT_STEPS = [
    {
      pathname: 'overview',
      label: t('common.overview'),
      icon: DocumentTextOutline,
    },
    {
      pathname: 'projects',
      label: t('pages.projects.overview.title'),
      icon: FolderOutline,
    },
    {
      pathname: 'user',
      label: t('pages.user.overview.title'),
      icon: PersonIdOutline,
    },
  ];

  if (featureFlags.billing_enabled.enabled && isAdminOfOrg(id)) {
    EDIT_STEPS.push({
      pathname: 'billing',
      label: 'Billing',
      icon: GraphOutline,
    });
  }

  if (isLoadingOrganization) {
    return (
      <Stack alignItems="center" gap={2} height="100%" justifyContent="center" width="100%">
        <CircularProgress />
        <Typography>{t('common.loading')}</Typography>
      </Stack>
    );
  }

  if (!id || !organization) {
    return <Box>{t('pages.organization.form.info.noOrganizationGiven')}</Box>;
  }

  const tabIndex = EDIT_STEPS.findIndex((step) => pathname.includes(step.pathname));

  const handleClickTab = (stepIndex: number) => () => {
    navigate(`/organizations/${id}/${EDIT_STEPS[stepIndex].pathname}`, {
      state: { organization },
    });
  };

  return (
    <DetailPageLayout title={organization.name}>
      <Box>
        <Tabs aria-label="organization-edit-tabs" onChange={() => ({})} value={tabIndex}>
          {EDIT_STEPS.map((stepData, index) => {
            const IconComponent = stepData.icon;
            let label = stepData.label;

            if (stepData.pathname === 'user')
              label = `${stepData.label} (${organization.usersCount})`;
            if (stepData.pathname === 'projects')
              label = `${stepData.label} (${organization.projectsCount})`;

            return (
              <Tab
                aria-controls={`organization-edit-tabpanel-${index}`}
                icon={<IconComponent height={24} width={24} />}
                iconPosition="start"
                id={`organization-edit-tab-${index}`}
                key={stepData.pathname}
                label={label}
                onClick={handleClickTab(index)}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box my={2} />
      <Outlet />
    </DetailPageLayout>
  );
};
