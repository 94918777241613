import { Autocomplete, Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useOrganizationsApi } from '@hooks/api/organizations';
import { useUsersUrlSync } from '@hooks/api/users';

export const UserFilterBar = () => {
  const { t } = useTranslation();
  const { data: organizationsData } = useOrganizationsApi();
  const { nameOrEmail, organizationId, handleNameOrEmailChange, handleOrganizationChange } =
    useUsersUrlSync();

  return (
    <Box display="flex" gap={2} mb={4}>
      <Box flexBasis="50%">
        <TextField
          data-testid="search-field"
          fullWidth
          onChange={(event) => {
            const searchString = event.currentTarget.value;

            handleNameOrEmailChange(searchString);
          }}
          placeholder={t('pages.user.overview.components.filter.name')}
          type="search"
          value={nameOrEmail}
        />
      </Box>
      <Box flexBasis="25%">
        <Autocomplete
          getOptionLabel={(p) => p.name}
          id="user-table-filter-by-organization"
          onChange={(_, value): void => {
            handleOrganizationChange(String(value?.id ?? ''));
          }}
          options={organizationsData?.content ?? []}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('pages.user.overview.components.filter.organization')}
            />
          )}
          value={organizationsData?.content.find((org) => org.id === organizationId) || null}
        />
      </Box>
    </Box>
  );
};
