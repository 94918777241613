import { type IdToken, useAuth0 } from '@auth0/auth0-react';
import flagsmith from 'flagsmith';
import { useEffect } from 'react';

export const useFlagsmithRole = () => {
  const { getIdTokenClaims } = useAuth0();

  useEffect(() => {
    const getClaims = async () => {
      const claims = await getIdTokenClaims();

      const { sub } = claims as IdToken;

      const isDeepUpUser = sub.startsWith('google-oauth2|');

      await flagsmith.setTrait('role', isDeepUpUser ? 'deepup' : 'customer');
    };

    getClaims();
  }, [getIdTokenClaims]);
};
