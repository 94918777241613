import { Button, CircularProgress, TextField, useTheme, FormHelperText } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';

interface SMANumberCloseDialogProps {
  open: boolean;
  onClick: (hasConfirmed: boolean) => void;
  isDeletingActive?: boolean;
  selectedSMANumber: string | null;
}

export const SMANumberCloseDialog: FC<SMANumberCloseDialogProps> = ({
  open,
  onClick,
  isDeletingActive,
  selectedSMANumber,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [wordToExplicitlyDelete, setWordToExplicitlyDelete] = useState<string | null>();
  const [showError, setShowError] = useState(false);
  const wordToConfirm = t('pages.smaNumbers.edit.dialog.confirmationKey');
  const hasError = wordToExplicitlyDelete !== wordToConfirm;

  const handleConfirm = () => {
    if (hasError) {
      setShowError(true);

      return;
    }
    onClick(true);
    setWordToExplicitlyDelete(null);
    setShowError(false);
  };

  const handleCancelCallback = () => {
    if (isDeletingActive) return;
    onClick(false);
    setWordToExplicitlyDelete(null);
    setShowError(false);
  };

  return (
    <Dialog maxWidth="xs" onClose={handleCancelCallback} open={open}>
      <DialogTitle>
        {t('pages.smaNumbers.edit.dialog.title', {
          smaNumber: selectedSMANumber,
        })}
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ marginBottom: theme.spacing(2), whiteSpace: 'pre-line' }}>
          {t('pages.smaNumbers.edit.dialog.content')}
        </Typography>
        <Typography>{t('pages.smaNumbers.edit.dialog.confirmationText')}</Typography>
        <TextField
          error={showError}
          fullWidth
          onChange={(e) => {
            setWordToExplicitlyDelete(e.target.value);
            if (showError) setShowError(false);
          }}
          placeholder={t('pages.smaNumbers.edit.dialog.confirmationPlaceholder')}
          value={wordToExplicitlyDelete || ''}
        />
        {showError && (
          <FormHelperText error>
            {t('pages.smaNumbers.edit.dialog.confirmationError')}
          </FormHelperText>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: theme.spacing(3), gap: theme.spacing(2) }}>
        <Button
          color="secondary"
          data-testid="strict-cancel-delete"
          disabled={isDeletingActive}
          onClick={handleCancelCallback}
          variant="outlined"
        >
          {t('pages.smaNumbers.edit.dialog.btnCancel')}
        </Button>
        <Button
          color="primary"
          data-testid="strict-confirm-delete"
          disabled={isDeletingActive}
          onClick={handleConfirm}
          startIcon={
            isDeletingActive ? (
              <CircularProgress color="secondary" size={theme.spacing(2)} />
            ) : undefined
          }
        >
          {isDeletingActive
            ? t('pages.smaNumbers.edit.dialog.btnSaving')
            : t('pages.smaNumbers.edit.dialog.btnSave')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
