import { Auth0Provider } from '@auth0/auth0-react';
import { LoadingScreen } from '@deepup/loading-screen';
import { DeepUpThemeProvider, useDeepUpTheme } from '@deepup/mui-theme-deepup';
import { CssBaseline, type Theme } from '@mui/material';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { SnackbarProvider } from 'notistack';
import { Suspense, useEffect, type FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { Auth } from '@components/Auth';
import { snackbarVariants } from '@components/DeepUpNotifications';
import { GrpcRestTransportProvider } from '@hooks/api/useGrpcTransport';
import { useEnvironment } from '@hooks/useEnvironment';

import { Router } from './Router';

export const App: FC = () => {
  const { auth0ClientId, auth0Domain, auth0Audience, flagsmithEnvId, flagsmithListeningInterval } =
    useEnvironment();
  const { theme } = useDeepUpTheme();

  useEffect(() => {
    if (!flagsmithListeningInterval) return;
    const interval = parseInt(flagsmithListeningInterval, 10);

    !isNaN(interval) && flagsmith.startListening(interval);
  }, [flagsmithListeningInterval]);

  const orgaManagementSpecificTheming: Partial<Theme> = {
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
          variant: 'contained',
          size: 'large',
        },
      },
    },
  };

  return (
    <FlagsmithProvider flagsmith={flagsmith} options={{ environmentID: flagsmithEnvId }}>
      <DeepUpThemeProvider args={[orgaManagementSpecificTheming]}>
        <CssBaseline />
        <SnackbarProvider Components={snackbarVariants} maxSnack={3}>
          <Auth0Provider
            authorizationParams={{
              redirect_uri: window.location.origin,
              audience: auth0Audience,
            }}
            clientId={auth0ClientId}
            domain={auth0Domain}
          >
            <Auth>
              <GrpcRestTransportProvider>
                <BrowserRouter>
                  <Suspense fallback={<LoadingScreen />}>
                    <QueryParamProvider adapter={ReactRouter6Adapter}>
                      <Router />
                    </QueryParamProvider>
                  </Suspense>
                </BrowserRouter>
              </GrpcRestTransportProvider>
            </Auth>
          </Auth0Provider>
        </SnackbarProvider>
      </DeepUpThemeProvider>
    </FlagsmithProvider>
  );
};
