import { useAuth0 } from '@auth0/auth0-react';
import { LoadingScreen } from '@deepup/loading-screen';
import { Alert, Container } from '@mui/material';
import type { ReactElement } from 'react';

import { useEnvironment } from '@hooks/useEnvironment';
import { usePlatformPermissions } from '@hooks/usePlatformPermissions';

const routesWithoutAuthentication = [
  '/users/accepted-invitation',
  '/users/register',
  '/users/register/success',
  '/privacy-policy',
];

export const Auth = ({ children }: { children: ReactElement }): ReactElement => {
  const { isAuthenticated, isLoading: isLoadingAuth, error, loginWithRedirect } = useAuth0();
  const { isLoading: isLoadingPermissions } = usePlatformPermissions();
  const { isDev } = useEnvironment();

  if (routesWithoutAuthentication.includes(window.location.pathname)) return children;

  if (isLoadingAuth) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated && error) {
    return (
      <Container>
        <Alert severity="error">{error.message}</Alert>
        <div style={{ margin: 8, color: 'white' }}>
          <span>Please </span>
          logout
          <span>, make sure to have the correct permission set and try again</span>
        </div>
      </Container>
    );
  }

  if (!isAuthenticated) {
    loginWithRedirect({ appState: { returnTo: window.location.href } }).catch(
      (e) => isDev && console.error(e),
    );

    return <LoadingScreen />;
  }

  if (isLoadingPermissions) {
    return <LoadingScreen />;
  }

  return children;
};
