import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EmptyTableInfo } from '@components/PageEntityTable/EmptyTableInfo';
import { useGroupsPaginatedApi } from '@hooks/api/groups/useGroupsPaginatedApi';
import { useGroupsUrlSync } from '@hooks/api/groups/useGroupsUrlSync';
import useDebounce from '@hooks/api/useDebounce';
import { useMapOrganizationsToEntities } from '@hooks/api/utils';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';
import { OverviewPageLayout } from '@pages/common';
import {
  filterGroupsByDeepUpOrganizations,
  filterGroupsByOrganizationId,
  filterGroupsWithOrgByName,
} from '@utils/groups';

import { GroupsFilterBar } from './GroupsFilterBar';
import { GroupsTable } from './GroupsTable';

interface GroupsOverviewProps {
  isDeepUpInternal?: boolean;
}

export const GroupsOverview: FC<GroupsOverviewProps> = ({ isDeepUpInternal = false }) => {
  const { t } = useTranslation();
  const { isItemVisible } = useRenderConfig();
  const navigate = useNavigate();

  const {
    page,
    size,
    sortModel,
    name,
    organizationId,
    handlePageChange,
    handleSizeChange,
    handleSortModelChange,
    handleNameChange,
    handleOrganizationChange,
  } = useGroupsUrlSync();

  const debouncedName = useDebounce(name);

  const {
    data: groupsData,
    isLoading: isLoadingGroups,
    error: groupsError,
  } = useGroupsPaginatedApi({
    page,
    size,
    sortModel,
    name: debouncedName,
    organizationId: organizationId,
    isDeepupOrg: isDeepUpInternal,
  });

  const {
    entitiesWithOrganization: groupsWithOrganization,
    isLoadingOrganizations,
    organizationsError,
  } = useMapOrganizationsToEntities({
    entities: groupsData?.content,
    isDeepUpOrg: isDeepUpInternal,
    mapping: (group, getOrganizationById) => ({
      ...group,
      organization: getOrganizationById(group.organizationId),
    }),
  });

  const isLoadingData =
    isLoadingOrganizations || isLoadingGroups || !groupsData || !groupsWithOrganization;
  const hasError = groupsError || organizationsError;
  const hasCreateButton = isItemVisible('groups.overview.components.btnCreate');

  const filteredGroupsByName = filterGroupsWithOrgByName(groupsWithOrganization, name);

  const filteredGroupsOfDeepUpOrganizations = filterGroupsByDeepUpOrganizations(
    filteredGroupsByName,
    isDeepUpInternal ? 'include' : 'exclude',
  );

  const filteredGroupsByOrganizationId =
    organizationId.length > 0
      ? filterGroupsByOrganizationId(filteredGroupsOfDeepUpOrganizations, organizationId)
      : filteredGroupsOfDeepUpOrganizations;

  const handleRowClick = (groupsId: string) => {
    const groupOfRow = filteredGroupsByOrganizationId.find((group) => group.id === groupsId);

    navigate(`/${isDeepUpInternal ? 'deepup-groups' : 'groups'}/${groupsId}/user`, {
      state: { group: groupOfRow },
    });
  };

  const pageTitle = isDeepUpInternal
    ? t('pages.groupsPermissions.deepup.title')
    : t('pages.groupsPermissions.overview.title');
  const rowCount = groupsData?.totalElements ?? -1;

  if (hasError) return <div>failed to load</div>;

  return (
    <OverviewPageLayout
      createRoute={
        hasCreateButton ? `/${isDeepUpInternal ? 'deepup-groups' : 'groups'}/create/overview` : ''
      }
      createTitle={t('pages.groupsPermissions.overview.components.btnCreate')}
      title={pageTitle}
    >
      <GroupsFilterBar
        handleNameChange={handleNameChange}
        handleOrganizationChange={handleOrganizationChange}
        isDeepUpInternal={isDeepUpInternal}
        name={name}
        organizationId={organizationId}
      />
      {(isLoadingData || filteredGroupsByOrganizationId.length > 0) && (
        <GroupsTable
          groups={filteredGroupsByOrganizationId}
          isDeepUpInternal={isDeepUpInternal}
          isLoading={isLoadingData}
          name={debouncedName}
          onEdit={handleRowClick}
          onPageChange={handlePageChange}
          onSizeChange={handleSizeChange}
          onSortModelChange={handleSortModelChange}
          organizationId={organizationId}
          page={page}
          rowCount={rowCount}
          size={size}
          sortModel={sortModel}
        />
      )}
      {!isLoadingData && filteredGroupsByOrganizationId.length <= 0 && (
        <EmptyTableInfo info={t('pages.groupsPermissions.overview.components.table.emptyInfo')} />
      )}
    </OverviewPageLayout>
  );
};
