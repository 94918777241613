import { Divider, Stack } from '@mui/material';
import type { FC, MouseEvent } from 'react';

import { ContextMenu, ContextMenuDeleteItem, ContextMenuEditItem } from '@components/ContextMenu';

interface GroupsContextMenuProps {
  hasEdit?: boolean;
  hasDelete?: boolean;
  onClickEdit?: () => void;
  onClickDelete: () => void;
  groupId: string;
  groupName: string;
  editLabel?: string;
}

export const GroupsContextMenu: FC<GroupsContextMenuProps> = ({
  groupId,
  groupName,
  hasEdit = false,
  hasDelete = true,
  onClickEdit,
  onClickDelete,
  editLabel,
}) => {
  const handleClickDelete = (event: MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    onClickDelete();
  };

  return (
    <ContextMenu data-testid={`group-context-menu-${groupName}`}>
      {hasEdit && <ContextMenuEditItem label={editLabel} onClick={onClickEdit} />}
      {hasDelete && (
        <Stack>
          <Divider />
          <ContextMenuDeleteItem
            data-testid={`delete-group-${groupId}`}
            onClick={handleClickDelete}
          />
        </Stack>
      )}
    </ContextMenu>
  );
};
