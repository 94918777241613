import Box from '@mui/material/Box';
import { DataGrid, type GridColDef } from '@mui/x-data-grid';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { FormSectionHeader } from '@components/FormElements';
import { EmptyTableInfo } from '@components/PageEntityTable/EmptyTableInfo';
import { useProjectApiAccessApi } from '@hooks/api/projects';

export const ProjectsEditApiAccesses: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useProjectApiAccessApi(id);
  const entities = data?.map((entity) => ({ id: entity.clientId, ...entity }));

  const handleClickRow = (id: string) => {
    navigate(`/api-accesses/${id}/overview`);
  };

  const columns: GridColDef[] = [
    {
      field: 'clientId',
      headerName: t('entities.apiAccesses.fields.clientId.label'),
      flex: 1,
    },
  ];

  const showTable = isLoading || (entities && entities.length > 0);

  return (
    <Box marginBottom={4}>
      <FormSectionHeader title={t('pages.projects.edit.header.apiAccesses.apiAccessesInProject')} />
      <Box display="flex" flexDirection="column">
        {showTable && (
          <DataGrid
            autoHeight
            columns={columns}
            disableColumnMenu
            disableRowSelectionOnClick
            loading={isLoading}
            onRowClick={(r) => handleClickRow(r.row.clientId)}
            rows={entities ?? []}
            slotProps={{
              loadingOverlay: {
                variant: 'skeleton',
                noRowsVariant: 'skeleton',
              },
            }}
          />
        )}
        {entities && entities.length <= 0 && (
          <EmptyTableInfo info={t('pages.projects.edit.messages.apiAccesses.noProjectsAdded')} />
        )}
      </Box>
    </Box>
  );
};
