import useSWR from 'swr';

import { useJsonFetch } from '@hooks/api/useJsonFetch';
import { deepUpOrganizationIds } from '@src/constants';

export enum UserStatus {
  pending = 'PENDING',
  invited = 'INVITED',
  verified = 'VERIFIED',
}

export enum PlatformUserType {
  platformAdmin = 'PLATFORM_ADMIN',
  organizationAdmin = 'ORGANIZATION_ADMIN',
  organizationMember = 'ORGANIZATION_MEMBER',
  external = 'EXTERNAL',
}

const [deepUpOrganizationId] = deepUpOrganizationIds;
const defaultPermissions: PlatformPermissions = {
  platformUserType: PlatformUserType.external,
  organizationId: deepUpOrganizationId,
};

export interface PlatformPermissionHook {
  isLoading: boolean;
  isValidating: boolean;
  permissions: PlatformPermissions;
  isAdminOfOrg: (orgId?: string | null) => boolean;
  isAdmin: boolean;
  isPlatformAdmin: boolean;
  isOrganizationAdmin: boolean;
  isOrganizationMember: boolean;
  isExternal: boolean;
}

export const usePlatformPermissions: () => PlatformPermissionHook = () => {
  const jsonFetch = useJsonFetch<PlatformPermissions>();
  const {
    data: permissions,
    isValidating,
    isLoading,
  } = useSWR('/platform/permissions', jsonFetch, {});

  const { platformUserType, organizationId } = permissions || defaultPermissions;
  const isPlatformAdmin = platformUserType === PlatformUserType.platformAdmin;
  const isOrganizationAdmin = platformUserType === PlatformUserType.organizationAdmin;
  const isAdmin = isPlatformAdmin || isOrganizationAdmin;

  const isOrganizationMember = platformUserType === PlatformUserType.organizationMember;
  const isExternal = platformUserType === PlatformUserType.external;

  const isAdminOfOrg = (orgId?: string | null): boolean => {
    if (isPlatformAdmin) return true;
    if (!orgId) return false;

    return isOrganizationAdmin && organizationId === orgId;
  };

  return {
    isLoading,
    isValidating,
    permissions: { platformUserType, organizationId },
    isAdminOfOrg,
    isAdmin,
    isPlatformAdmin,
    isOrganizationAdmin,
    isOrganizationMember,
    isExternal,
  };
};

export type PlatformPermissions = {
  platformUserType: PlatformUserType;
  organizationId?: string;
};
