import { timestampApi, type billingApiV1 } from '@deepup/apis';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

const getDurationInDays = (start: DateTime, end: DateTime) => {
  return Math.round(end.diff(start, 'days').days);
};

// Note: Rental price is calculated based on the number of days in the month,
// because the price is fixed for the whole month but the rental period can be shorter
const calculateRentalPrice = (start: DateTime, end: DateTime, basePrice: number) => {
  const daysInMonth = end.daysInMonth || 30;
  const rentDuration = getDurationInDays(start, end);

  return (basePrice / daysInMonth) * rentDuration;
};

const formatDateRange = (start: DateTime, end: DateTime, locale: string) => {
  return `${start.toLocaleString(DateTime.DATE_SHORT, {
    locale,
  })} - ${end.toLocaleString(DateTime.DATE_SHORT, { locale })}`;
};

const convertTimestampToDateTime = (timestamp: timestampApi.Timestamp) => {
  const date = timestampApi.Timestamp.toDate(timestamp);

  return DateTime.fromJSDate(date, { zone: 'utc' });
};

const processRentalRow = (row: billingApiV1.ScannerRent, locale: string) => {
  if (!row.timeRange?.start || !row.timeRange?.end) {
    return {
      id: row.scanner?.id,
      scannerName: row.scanner?.serialNumber,
      price: 0,
      rentDuration: 0,
      range: '',
    };
  }

  const start = convertTimestampToDateTime(row.timeRange.start);
  const end = convertTimestampToDateTime(row.timeRange.end);

  const rentDuration = getDurationInDays(start, end);
  const range = formatDateRange(start, end, locale);
  const price = calculateRentalPrice(start, end, row.price);

  return {
    id: row.scanner?.id,
    scannerName: row.scanner?.serialNumber,
    price,
    rentDuration,
    range,
  };
};

export const useRentalRows = (
  progress: billingApiV1.GetScannerRentResponse['items'],
  locale: string,
) => {
  return useMemo(() => {
    return progress.map((row) => processRentalRow(row, locale));
  }, [progress, locale]);
};
