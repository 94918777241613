import { Autocomplete, Box, TextField } from '@mui/material';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrganizationsApi } from '@hooks/api/organizations';

export const GroupsFilterBar = ({
  name,
  handleNameChange,
  handleOrganizationChange,
  isDeepUpInternal,
  organizationId,
}: {
  name: string;
  handleNameChange: (newSearchName: string) => void;
  handleOrganizationChange: (newSearchOrganization: string) => void;
  isDeepUpInternal: boolean;
  organizationId: string;
}) => {
  const { t } = useTranslation();
  const { data: organizationsData } = useOrganizationsApi({ isDeepUpOrg: isDeepUpInternal });
  const organizations = organizationsData?.content ?? [];

  const handleChangeSelectOrganization = (
    _: SyntheticEvent<Element, Event>,
    value: { id: string; name: string } | null,
  ) => {
    handleOrganizationChange(value?.id ?? '');
  };

  return (
    <Box display="flex" gap={2} mb={4}>
      <Box flexBasis="50%">
        <TextField
          fullWidth
          onChange={(event) => {
            const searchString = event.currentTarget.value;

            handleNameChange(searchString);
          }}
          placeholder={t('pages.groupsPermissions.overview.components.filter.name')}
          type="search"
          value={name}
        />
      </Box>
      <Box flexBasis="25%">
        <Autocomplete
          getOptionLabel={(p) => p.name}
          onChange={handleChangeSelectOrganization}
          options={organizations}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('pages.groupsPermissions.overview.components.filter.organization')}
            />
          )}
          value={organizations.find((org) => org.id === organizationId) || null}
        />
      </Box>
    </Box>
  );
};
