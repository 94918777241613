import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';
import { useFlags } from 'flagsmith/react';
import { useEffect, useState, type FC, type FormEvent } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BottomFormNavigation } from '@components/BottomFormNavigation';
import { FormInput, FormSectionHeader } from '@components/FormElements';
import { FormSectionSubHeader } from '@components/FormElements/FormSectionHeader';
import type { Organization } from '@hooks/api/organizations';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';
import { usePlatformPermissions } from '@hooks/usePlatformPermissions';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';

import { organizationFormSchema, type OrganizationFormSchema } from './schema';
import { CountryCodeFormType } from './sections/CountryCodeFormType';
import { OrganizationsFormInvoiceType } from './sections/OrganizationsFormInvoiceType';

interface OrganizationFormProps {
  organization?: Organization;
  onSubmit: (data: OrganizationFormSchema) => void;
}

export const OrganizationForm: FC<OrganizationFormProps> = ({ onSubmit, organization }) => {
  const { t } = useTranslation();
  const { isItemVisible, isItemEnabled } = useRenderConfig();
  const navigate = useNavigate();
  const { showMessage } = useSnackbarMessage();
  const featureFlags = useFlags(['invoice_tool_permissions_enabled']);
  const { isPlatformAdmin } = usePlatformPermissions();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const organizationFormSchemaWithTranslation = organizationFormSchema({
    error: {
      name: t('entities.organization.fields.name.error'),
      dashboardSource: t('entities.organization.fields.dashboardSource.error'),
      onlySpaces: t('entities.organization.fields.name.errorOnlySpaces'),
      countryCodeFormat: t('entities.organization.fields.billing.country.error'),
      priceModel: t('entities.organization.fields.priceModel.error'),
    },
  });

  const {
    register,
    handleSubmit: formSubmitHandler,
    control,
  } = useForm({
    resolver: yupResolver(organizationFormSchemaWithTranslation),
  });
  const { errors: formErrors } = useFormState({ control });

  const handleClickCancel = () => {
    navigate('/organizations');
  };

  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
      showMessage({ message: t('common.missingData'), type: 'error' });
    }
  }, [formErrors, t, showMessage]);

  const isFormEnabled = isItemEnabled('organizations.edit.form.mode');
  const hasDashboardFrameSourceInput = isItemVisible(
    'organizations.edit.form.fields.dashboardIframeSourceUrl',
  );
  const enableInvoiceToolVisible =
    isItemVisible('organizations.edit.form.fields.invoiceToolEnabled') &&
    featureFlags.invoice_tool_permissions_enabled.enabled;

  const enableAssetReviewVisible = isPlatformAdmin;

  const billingDetailsVisible = isPlatformAdmin;

  const handleSubmit = async (event: FormEvent) => {
    setIsSubmitting(true);
    await formSubmitHandler(onSubmit)(event);
    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column">
        <Box marginBottom={2}>
          <FormSectionHeader title={t('common.masterData')} />
          <FormInput
            defaultValue={organization?.name}
            disabled={!isFormEnabled}
            error={formErrors.name}
            formFieldName="name"
            isRequired
            label={t('entities.organization.fields.name.label')}
            register={register}
          />

          <FormInput
            defaultValue={organization?.abbreviation}
            disabled={!isFormEnabled}
            error={formErrors.abbreviation}
            formFieldName="abbreviation"
            label={t('entities.organization.fields.abbreviation.label')}
            register={register}
          />
          {hasDashboardFrameSourceInput && (
            <FormInput
              defaultValue={organization?.dashboardSource}
              disabled={!isFormEnabled}
              error={formErrors.dashboardSource}
              formFieldName="dashboardSource"
              helperText={t('entities.organization.fields.dashboardSource.placeholder')}
              label={t('entities.organization.fields.dashboardSource.label')}
              register={register}
            />
          )}
        </Box>
        {enableInvoiceToolVisible && (
          <Box marginBottom={2}>
            <FormSectionHeader title={t('pages.organization.form.sections.invoiceTool')} />
            <FormControlLabel
              control={
                <Checkbox
                  {...register('invoiceToolEnabled')}
                  defaultChecked={organization?.invoiceToolEnabled}
                />
              }
              label={t('entities.organization.fields.invoiceToolEnabled.label')}
            />
          </Box>
        )}
        {enableAssetReviewVisible && (
          <Box marginBottom={2}>
            <FormSectionHeader title={t('pages.organization.form.sections.dbbAssetReview')} />
            <FormControlLabel
              control={
                <Checkbox
                  {...register('dbbAssetReviewEnabled')}
                  defaultChecked={organization?.dbbAssetReviewEnabled}
                />
              }
              label={t('entities.organization.fields.dbbAssetReviewEnabled.label')}
            />
          </Box>
        )}
        {billingDetailsVisible && (
          <Box marginBottom={2}>
            <FormSectionHeader title={t('pages.organization.form.sections.billing')} />
            <FormSectionSubHeader
              title={t('pages.organization.form.sections.billingSections.pricePerMeter')}
            />
            <FormInput
              defaultValue={organization?.priceModel?.pricePerMeterClosed}
              disabled={!isFormEnabled}
              error={formErrors.priceModel?.pricePerMeterClosed}
              formFieldName="priceModel.pricePerMeterClosed"
              label={t('entities.organization.fields.billing.closedConstruction.label')}
              register={register}
            />
            <FormInput
              defaultValue={organization?.priceModel?.pricePerMeterOpen}
              disabled={!isFormEnabled}
              error={formErrors.priceModel?.pricePerMeterOpen}
              formFieldName="priceModel.pricePerMeterOpen"
              label={t('entities.organization.fields.billing.openConstruction.label')}
              register={register}
            />
            <FormInput
              defaultValue={organization?.priceModel?.pricePerMeterLayjet}
              disabled={!isFormEnabled}
              error={formErrors.priceModel?.pricePerMeterLayjet}
              formFieldName="priceModel.pricePerMeterLayjet"
              label={t('entities.organization.fields.billing.layjetConstruction.label')}
              register={register}
            />
            <FormInput
              defaultValue={organization?.priceModel?.pricePerMeterOther}
              disabled={!isFormEnabled}
              error={formErrors.priceModel?.pricePerMeterOther}
              formFieldName="priceModel.pricePerMeterOther"
              label={t('entities.organization.fields.billing.otherConstruction.label')}
              register={register}
            />
            <FormSectionSubHeader
              title={t('pages.organization.form.sections.billingSections.scannerRent')}
            />
            <FormInput
              defaultValue={organization?.priceModel?.pricePerScannerPerMonth}
              disabled={!isFormEnabled}
              error={formErrors.priceModel?.pricePerScannerPerMonth}
              formFieldName="priceModel.pricePerScannerPerMonth"
              label={t('entities.organization.fields.billing.scannerRent.label')}
              register={register}
            />
            <FormSectionSubHeader
              title={t('pages.organization.form.sections.billingSections.invoiceType')}
            />
            <OrganizationsFormInvoiceType
              control={control}
              formErrors={formErrors}
              organization={organization}
            />
            <FormSectionSubHeader
              title={t('pages.organization.form.sections.billingSections.billingAddress')}
            />
            <FormInput
              defaultValue={organization?.billingAddress?.companyName}
              disabled={!isFormEnabled}
              error={formErrors.billingAddress?.companyName}
              formFieldName="billingAddress.companyName"
              label={t('entities.organization.fields.billing.companyName.label')}
              register={register}
            />
            <FormInput
              defaultValue={organization?.billingAddress?.streetAndNumber}
              disabled={!isFormEnabled}
              error={formErrors.billingAddress?.streetAndNumber}
              formFieldName="billingAddress.streetAndNumber"
              label={t('entities.organization.fields.billing.streetAndNumber.label')}
              register={register}
            />
            <FormInput
              defaultValue={organization?.billingAddress?.zipCode}
              disabled={!isFormEnabled}
              error={formErrors.billingAddress?.zipCode}
              formFieldName="billingAddress.zipCode"
              label={t('entities.organization.fields.billing.zipcode.label')}
              register={register}
            />
            <FormInput
              defaultValue={organization?.billingAddress?.city}
              disabled={!isFormEnabled}
              error={formErrors.billingAddress?.city}
              formFieldName="billingAddress.city"
              label={t('entities.organization.fields.billing.city.label')}
              register={register}
            />
            <CountryCodeFormType
              control={control}
              formErrors={formErrors}
              organization={organization}
            />
            <FormSectionSubHeader
              title={t('pages.organization.form.sections.billingSections.vat')}
            />
            <FormInput
              defaultValue={organization?.billingAddress?.vatNumber}
              disabled={!isFormEnabled}
              error={formErrors.billingAddress?.vatNumber}
              formFieldName="billingAddress.vatNumber"
              label={t('entities.organization.fields.billing.vatNumber.label')}
              register={register}
            />
          </Box>
        )}
        <BottomFormNavigation
          hasNext={isFormEnabled}
          isLoadingNext={isSubmitting}
          nextType="submit"
          onGoBack={handleClickCancel}
        />
      </Box>
    </form>
  );
};
