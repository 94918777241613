import type { SelectItem } from '@components/FormElements';

import { useApiGetInvoicedMeters } from './useApiGetInvoicedMeters';

export const getDateRangeFromMonthId = (monthId: string) => {
  const [start, end] = monthId.split('_');

  return { start, end };
};

export const mapProjectIds = (selectedProjects: SelectItem[] | null, projectList: SelectItem[]) => {
  return selectedProjects && selectedProjects.length > 0
    ? selectedProjects.map((item) => item.id)
    : projectList.map((item) => item.id);
};

export const useInvoicedMetersData = ({
  organizationId,
  month,
  selectedProjects,
  projectList,
  paginationModel,
}: {
  organizationId: string | null;
  month: { id: string };
  selectedProjects: SelectItem[] | null;
  projectList: SelectItem[];
  paginationModel: { page: number; pageSize: number };
}) => {
  const dateRange = getDateRangeFromMonthId(month.id);
  const projectIds = mapProjectIds(selectedProjects, projectList);

  return useApiGetInvoicedMeters({
    organizationId,
    dateRange,
    projectIds,
    listOptions: paginationModel,
  });
};
