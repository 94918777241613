import {
  Autocomplete,
  Checkbox,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export type SelectItem = {
  id: string;
  name: string;
};

type MultiSelectBaseProps = {
  label: string;
  items: SelectItem[];
  helperText?: string;
  disabled?: boolean;
};

export const MultiSelect = ({
  label,
  items,
  selectedItems,
  setSelectedItems,
  helperText = '',
  disabled,
}: MultiSelectBaseProps & {
  selectedItems: SelectItem[];
  setSelectedItems: (nextSelected: SelectItem[]) => void;
}) => {
  const { t } = useTranslation();
  const toggleAll = () => {
    setSelectedItems(selectedItems.length === items.length ? [] : items);
  };

  const allChecked = selectedItems.length === items.length;
  const onlySomeChecked = selectedItems.length > 0 && selectedItems.length < items.length;

  return (
    <Autocomplete
      disableCloseOnSelect
      disabled={disabled}
      fullWidth
      getOptionLabel={(option) => option.name}
      groupBy={() => t('common.selectAll')}
      limitTags={0}
      multiple
      onChange={(event, nextItems) => {
        setSelectedItems(nextItems);
      }}
      options={items}
      renderGroup={({ children, key }) => (
        // this needs a div with key to avoid react warning
        <div key={key}>
          <ListItemButton onClick={toggleAll}>
            <ListItemIcon>
              <Checkbox checked={allChecked} indeterminate={onlySomeChecked} />
            </ListItemIcon>
            <ListItemText>{t('common.selectAll')}</ListItemText>
          </ListItemButton>
          <Divider />
          {children}
        </div>
      )}
      renderInput={(params) => (
        <TextField {...params} helperText={helperText} label={label} placeholder={label} />
      )}
      renderOption={(props, item, { selected }) => (
        <ListItem {...props} key={item.id}>
          <ListItemIcon>
            <Checkbox checked={selected} />
          </ListItemIcon>
          <ListItemText primary={item.name} />
        </ListItem>
      )}
      renderTags={(items) => '+' + items.length}
      selectOnFocus
      slotProps={{ paper: { elevation: 6 } }}
      value={selectedItems}
    />
  );
};

const diffItems = (items: SelectItem[], selected: SelectItem[]) => {
  const selectedIds = selected.map((s) => s.id);

  return items.filter((item) => !selectedIds.includes(item.id));
};

export const namesToItems = (names: string[]) => names.map((name) => ({ id: name, name }));
export const itemsToNames = (items: SelectItem[]) => items.map((item) => item.name);

export const MultiSelectInversed = ({
  items,
  unselectedItems,
  setUnselectedItems,
  ...rest
}: MultiSelectBaseProps & {
  unselectedItems: SelectItem[];
  setUnselectedItems: (selected: SelectItem[]) => void;
}) => {
  const selectedItems = diffItems(items, unselectedItems);

  const setUnselected = (nextSelected: SelectItem[]) => {
    const unselected = diffItems(items, nextSelected);

    setUnselectedItems(unselected);
  };

  return (
    <MultiSelect
      {...rest}
      items={items}
      selectedItems={selectedItems}
      setSelectedItems={setUnselected}
    />
  );
};
