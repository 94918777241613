import Box from '@mui/material/Box';
import type { GridSortModel } from '@mui/x-data-grid';
import { useState, type FC } from 'react';

import { type Organization } from '@hooks/api/organizations';
import { useOrganizationsPaginatedApi } from '@hooks/api/organizations/useOrganizationsPaginatedApi';
import { type ProjectWithOrganizations } from '@hooks/api/projects';
import { useProjectsPaginatedApi } from '@hooks/api/projects/useProjectsPaginatedApi';
import { ProjectsTable } from '@pages/Projects/ProjectsOverview/ProjectsTable';

import { useFormOrganizationState } from './useFormOrganizationState';

export const OrganizationsEditProjects: FC = () => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const {
    data: organizationsData,
    error: organizationsError,
    isLoading: isLoadingOrganizations,
  } = useOrganizationsPaginatedApi({
    page,
    size,
    sortModel,
  });

  const { organization } = useFormOrganizationState();

  const {
    data: projectsData,
    error: projectsError,
    isLoading: isLoadingProjects,
  } = useProjectsPaginatedApi({
    page,
    size,
    sortModel,
    organizationId: organization?.id,
  });

  const isLoading = isLoadingOrganizations || isLoadingProjects;

  if (projectsError || organizationsError) return <Box>Error while fetching data</Box>;

  const projectsOfOrganization = projectsData?.content.filter(
    (project) => project.organizationId === organization?.id,
  );

  const projectsWithOrganizationInfo: ProjectWithOrganizations[] = projectsOfOrganization
    ? projectsOfOrganization.map((project) => {
        const findOrgaById = (orgaId?: string | null) =>
          orgaId
            ? organizationsData?.content.find(
                (organization: Organization) => organization.id === orgaId,
              )
            : undefined;

        const organization = findOrgaById(project.organizationId) as Organization;
        const networkProvider = findOrgaById(project.networkProviderId);
        const generalContractor = findOrgaById(project.generalContractorId);

        return {
          ...project,
          organization,
          networkProvider,
          generalContractor,
        } as ProjectWithOrganizations;
      })
    : [];

  const rowCount = projectsData?.totalElements ?? -1;

  return (
    <ProjectsTable
      isLoading={isLoading}
      onPageChange={setPage}
      onSizeChange={setSize}
      onSortModelChange={setSortModel}
      page={page}
      projects={projectsWithOrganizationInfo}
      rowCount={rowCount}
      size={size}
      sortModel={sortModel}
    />
  );
};
