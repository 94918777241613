import { type ChangeEvent, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyTableInfo } from '@components/PageEntityTable/EmptyTableInfo';
import { useProjectsPaginatedApi } from '@hooks/api/projects/useProjectsPaginatedApi';
import { useProjectsUrlSync } from '@hooks/api/projects/useProjectsUrlSync';
import useDebounce from '@hooks/api/useDebounce';
import { useMapOrganizationsToEntities } from '@hooks/api/utils';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';
import { OverviewPageLayout } from '@pages/common';

import { ProjectsFilterBar } from './ProjectsFilterBar';
import { ProjectsTable } from './ProjectsTable';

export const ProjectsOverview: FC = () => {
  const { t } = useTranslation();
  const { isItemVisible } = useRenderConfig();

  const {
    page,
    size,
    sortModel,
    name,
    generalContractorId,
    networkProviderId,
    customerManager,
    customerContactPerson,
    contactPerson,
    serviceProviderId,
    networkDesignState,
    networkDesignFormat,
    activityState,
    pipeDirection,
    handlePageChange,
    handleSizeChange,
    handleSortModelChange,
    handleNameChange,
    handleNetworkProviderIdChange,
    handleGeneralContractorIdChange,
    handleCustomerManagerChange,
    handleCustomerContactPersonChange,
    handleContactPersonChange,
    handleServiceProviderIdChange,
    handleNetworkDesignStateChange,
    handleNetworkDesignFormatChange,
    handleActivityStateChange,
    handlePipeDirectionChange,
  } = useProjectsUrlSync();

  const debouncedName = useDebounce(name);

  const {
    data: projectsData,
    error: projectsError,
    isLoading: isLoadingProjects,
  } = useProjectsPaginatedApi({
    page,
    size,
    sortModel,
    name: debouncedName,
    networkProviderId,
    generalContractorId,
    customerManager,
    customerContactPerson,
    contactPerson,
    serviceProviderId,
    networkDesignState,
    networkDesignFormat,
    activityState,
    pipeDirection,
  });

  const { entitiesWithOrganization: projectsWithOrganization, isLoadingOrganizations } =
    useMapOrganizationsToEntities({
      entities: projectsData?.content,
      mapping: (project, getOrgById) => ({
        ...project,
        organization: getOrgById(project.organizationId ?? ''),
        networkProvider: getOrgById(project.networkProviderId ?? ''),
        generalContractor: getOrgById(project.generalContractorId ?? ''),
      }),
    });

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    const searchString = event.currentTarget.value;

    handleNameChange(searchString);
  };

  const rowCount = projectsData?.totalElements ?? -1;
  const hasCreateButton = isItemVisible('projects.overview.components.btnCreate');
  const hasProjects = projectsWithOrganization.length > 0;

  if (projectsError) return <div>failed to load</div>;

  const isLoading = isLoadingProjects || isLoadingOrganizations;

  return (
    <OverviewPageLayout
      createRoute={hasCreateButton ? '/projects/create' : ''}
      createTitle={t('pages.projects.overview.components.btnCreate')}
      title={t('pages.projects.overview.title')}
    >
      <>
        <ProjectsFilterBar
          activityState={activityState}
          contactPerson={contactPerson}
          customerContactPerson={customerContactPerson}
          customerManager={customerManager}
          generalContractorId={generalContractorId}
          networkDesignFormat={networkDesignFormat}
          networkDesignState={networkDesignState}
          networkOperatorId={networkProviderId}
          onChangeActivityState={handleActivityStateChange}
          onChangeContactPerson={handleContactPersonChange}
          onChangeCustomerContactPerson={handleCustomerContactPersonChange}
          onChangeCustomerManager={handleCustomerManagerChange}
          onChangeGeneralContractorId={handleGeneralContractorIdChange}
          onChangeName={handleChangeName}
          onChangeNetworkDesignFormat={handleNetworkDesignFormatChange}
          onChangeNetworkDesignState={handleNetworkDesignStateChange}
          onChangeNetworkOperatorId={handleNetworkProviderIdChange}
          onChangePipeDirection={handlePipeDirectionChange}
          onChangeServiceProviderId={handleServiceProviderIdChange}
          pipeDirection={String(pipeDirection)}
          searchName={name}
          serviceProviderId={serviceProviderId}
        />
        {!hasProjects && !isLoading && (
          <EmptyTableInfo info={t('pages.projects.overview.components.table.emptyInfo')} />
        )}
        {(hasProjects || isLoading) && (
          <ProjectsTable
            isLoading={isLoading}
            onPageChange={handlePageChange}
            onSizeChange={handleSizeChange}
            onSortModelChange={handleSortModelChange}
            page={page}
            projects={projectsWithOrganization}
            rowCount={rowCount}
            searchName={debouncedName}
            size={size}
            sortModel={sortModel || []}
          />
        )}
      </>
    </OverviewPageLayout>
  );
};
