import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useRenderConfig } from '@hooks/ui';
import { useFlagsmithRole } from '@hooks/useFlagsmithRole';
import {
  ApiAccesses,
  ApiAccessesForm,
  ApiAccessesFormCreate,
  ApiAccessesFormDetails,
  ApiAccessesFormOverview,
  ApiAccessesFormProjects,
  ApiAccessesOverview,
} from '@pages/ApiAccesses';
import {
  Groups,
  GroupsForm,
  GroupsFormCreate,
  GroupsFormEdit,
  GroupsFormOverview,
  GroupsFormPermissions,
  GroupsFormUser,
  GroupsOverview,
} from '@pages/Groups';
import {
  Organizations,
  OrganizationsCreate,
  OrganizationsEdit,
  OrganizationsEditDashboard,
  OrganizationsEditOverview,
  OrganizationsEditProjects,
  OrganizationsEditUser,
  OrganizationsOverview,
} from '@pages/Organizations';
import {
  Projects,
  ProjectsCreate,
  ProjectsEdit,
  ProjectsEditApiAccesses,
  ProjectsEditOverview,
  ProjectsOverview,
  SMANumberList,
} from '@pages/Projects';
import {
  UserAcceptedInvitation,
  UserManagement,
  UserManagementCreate,
  UserManagementEdit,
  UserManagementEditForm,
  UserManagementOverview,
  UserManagementPermissions,
  UserRegister,
  UserRegisterForm,
  UserRegisterSuccess,
} from '@pages/UserManagement';
import { PageNotFound, PrivacyPolicy } from '@pages/index';

import { Layout } from './Layout';

export const Router: FC = () => {
  useFlagsmithRole();

  const { isRouteEnabled } = useRenderConfig();

  return (
    <Routes>
      <Route element={<Layout />} path="/">
        {isRouteEnabled('organizations') && (
          <Route element={<Organizations />} path="organizations">
            <Route element={<OrganizationsCreate />} path="create" />
            <Route element={<OrganizationsEdit />} path=":id">
              <Route element={<OrganizationsEditOverview />} path="overview" />
              <Route element={<OrganizationsEditProjects />} path="projects" />
              <Route element={<OrganizationsEditDashboard />} path="billing" />
              <Route element={<OrganizationsEditUser />} path="user" />
            </Route>
            <Route element={<OrganizationsOverview />} index />
          </Route>
        )}
        {isRouteEnabled('projects') && (
          <Route element={<Projects />} path="projects">
            <Route element={<ProjectsCreate />} path="create" />
            <Route element={<ProjectsEdit />} path=":id">
              <Route element={<ProjectsEditOverview />} path="overview" />
              {isRouteEnabled('apiAccesses') && (
                <Route element={<ProjectsEditApiAccesses />} path="api-accesses" />
              )}
              <Route element={<SMANumberList />} path="sma-numbers" />
            </Route>
            <Route element={<ProjectsOverview />} index />
          </Route>
        )}
        {isRouteEnabled('groups') && (
          <Route element={<Groups />} path="groups">
            <Route
              element={
                <GroupsForm>{(baseProps) => <GroupsFormCreate {...baseProps} />}</GroupsForm>
              }
              path="create"
            >
              <Route element={<GroupsFormOverview />} path="overview" />
              <Route element={<GroupsFormPermissions />} path="projects" />
              <Route element={<GroupsFormUser />} path="user" />
            </Route>
            <Route
              element={<GroupsForm>{(baseProps) => <GroupsFormEdit {...baseProps} />}</GroupsForm>}
              path=":id"
            >
              <Route element={<GroupsFormOverview isEdit />} path="overview" />
              <Route element={<GroupsFormPermissions isEdit />} path="projects" />
              <Route element={<GroupsFormUser isEdit />} path="user" />
            </Route>
            <Route element={<GroupsOverview />} index />
          </Route>
        )}
        {isRouteEnabled('deepupGroups') && (
          <Route element={<Groups />} path="deepup-groups">
            <Route
              element={
                <GroupsForm isDeepUpInternal>
                  {(baseProps) => <GroupsFormCreate {...baseProps} />}
                </GroupsForm>
              }
              path="create"
            >
              <Route element={<GroupsFormOverview isDeepUpInternal />} path="overview" />
              <Route element={<GroupsFormPermissions isDeepUpInternal />} path="projects" />
              <Route element={<GroupsFormUser isDeepUpInternal />} path="user" />
            </Route>
            <Route
              element={
                <GroupsForm isDeepUpInternal>
                  {(baseProps) => <GroupsFormEdit {...baseProps} />}
                </GroupsForm>
              }
              path=":id"
            >
              <Route element={<GroupsFormOverview isDeepUpInternal isEdit />} path="overview" />
              <Route element={<GroupsFormPermissions isDeepUpInternal />} path="projects" />
              <Route element={<GroupsFormUser isDeepUpInternal />} path="user" />
            </Route>
            <Route element={<GroupsOverview isDeepUpInternal />} index />
          </Route>
        )}
        {isRouteEnabled('user') && (
          <Route element={<UserManagement />} path="users">
            <Route element={<UserManagementCreate />} path="create" />
            <Route element={<UserManagementEdit />} path=":id">
              <Route element={<UserManagementEditForm />} path="edit" />
              <Route element={<UserManagementPermissions />} path="permissions" />
            </Route>
            <Route element={<UserManagementOverview />} index />
          </Route>
        )}
        {isRouteEnabled('apiAccesses') && (
          <Route element={<ApiAccesses />} path="api-accesses">
            <Route
              element={
                <ApiAccessesForm isCreate>
                  {(baseProps) => <ApiAccessesFormCreate {...baseProps} />}
                </ApiAccessesForm>
              }
              path="create"
            >
              <Route element={<ApiAccessesFormOverview isCreate />} path="overview" />
              <Route element={<ApiAccessesFormProjects isCreate />} path="projects" />
            </Route>
            <Route
              element={
                <ApiAccessesForm>
                  {(baseProps) => <ApiAccessesFormDetails {...baseProps} />}
                </ApiAccessesForm>
              }
              path=":id"
            >
              <Route element={<ApiAccessesFormOverview />} path="overview" />
              <Route element={<ApiAccessesFormProjects />} path="projects" />
            </Route>
            <Route element={<ApiAccessesOverview />} index />
          </Route>
        )}
        <Route element={<PageNotFound />} path="*" />
      </Route>
      <Route element={<UserRegister />} path="users/register">
        <Route element={<UserRegisterForm />} index />
        <Route element={<UserRegisterSuccess />} path="success" />
      </Route>
      <Route element={<UserAcceptedInvitation />} path="users/accepted-invitation" />
      <Route element={<PrivacyPolicy />} path="privacy-policy" />
    </Routes>
  );
};
