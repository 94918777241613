import type { GroupWithOrganization } from '@hooks/api/groups';

export function filterGroupsWithOrgByName(
  groupsWithOrganization: GroupWithOrganization[],
  searchName: string,
) {
  return searchName.length > 0
    ? groupsWithOrganization.filter((group) => {
        const groupNameLowerCase = group.name.toLowerCase();
        const groupOrganizationLowerCase = group.organization?.name.toLowerCase();

        const searchNameLowerCase = searchName.toLowerCase();

        return (
          groupNameLowerCase.indexOf(searchNameLowerCase) > -1 ||
          (groupOrganizationLowerCase &&
            groupOrganizationLowerCase?.indexOf(searchNameLowerCase) > -1)
        );
      })
    : groupsWithOrganization;
}

export function filterGroupsByOrganizationId(
  groupsWithOrganization: GroupWithOrganization[],
  organizationId: string,
) {
  return groupsWithOrganization.filter((group) => group.organization?.id === organizationId);
}

export function filterGroupsByDeepUpOrganizations(
  groupsWithOrganization: GroupWithOrganization[],
  includeOrExclude: 'include' | 'exclude',
) {
  return groupsWithOrganization.filter((group) => {
    if (includeOrExclude === 'include') return !!group.organization?.isDeepUpOrg;
    if (includeOrExclude === 'exclude') return !group.organization?.isDeepUpOrg;
  });
}
