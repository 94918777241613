import { useAuth0 } from '@auth0/auth0-react';
import { GlobalHeader } from '@deepup/global-header';
import { useMediaQuery, type Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { NavigationSidebar } from '@components/NavigationSidebar';
import { MobileNavigationSidebar } from '@components/NavigationSidebar/MobileNavigationSidebar';
import { ScrollToTop } from '@components/utils/ScrollToTop';

export const Layout: FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const { i18n } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'lg'));

  useEffect(() => {
    if (location.pathname === '/' && isAuthenticated) {
      // common entrypoint for all admins (platform and organization).
      // So for now opinionated on this from app perspective
      navigate('/projects');
    }
  }, [navigate, isAuthenticated]);

  return (
    <>
      <ScrollToTop />
      {isAuthenticated && (
        <GlobalHeader
          guideConfig={{ appIdentifier: 'organization-management' }}
          language={i18n.language as 'de' | 'en' | undefined}
          languageSelectors={[{ key: 'de' }, { key: 'en' }]}
          onChangeLanguage={(selectedLanguage) => {
            i18n.changeLanguage(selectedLanguage);
          }}
          showLanguageToggle
          showThemeToggle
          title="Organization Management"
        />
      )}
      <Box display="flex" flex={1}>
        {isMobile || isTablet ? (
          <MobileNavigationSidebar isMobile={isMobile} />
        ) : (
          <NavigationSidebar />
        )}
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          gap={2}
          paddingLeft={isMobile || isTablet ? 10 : 45}
          paddingRight={isMobile || isTablet ? 4 : 8}
          paddingY={13}
          width="100%"
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
};
