import { billingApiV1 as billingApi } from '@deepup/apis';
import { useState } from 'react';
import type { TriggerWithoutArgs } from 'swr/dist/mutation';

import { downloadFile } from '@utils/downloadFile';

import { useApiDownloadStatus } from './useApiGetInvoicedMeters';

export const useDownloadHandler = () => {
  const [downloadId, setDownloadId] = useState<string | null>(null);

  const { trigger: downloadStatus, error } = useApiDownloadStatus({
    id: downloadId || '',
  });

  const handleDownload = async (
    createDownloadFn: TriggerWithoutArgs<billingApi.DownloadResponse>,
  ) => {
    setDownloadId('');
    const response = await createDownloadFn();

    if (!response) return;

    setDownloadId(response.downloadId);

    const interval = setInterval(async () => {
      const statusResponse = await downloadStatus();

      if (!statusResponse) return;

      if (statusResponse?.status === 1) {
        clearInterval(interval);
        downloadFile(statusResponse.fileUrl);
        setDownloadId(null);
      }
    }, 2000);
  };

  return {
    downloadId,
    handleDownload,
    error,
  };
};
