import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface GroupsFormNavigationProps {
  backTitle?: string;
  backType?: 'button' | 'submit' | 'reset';
  onGoBack?: () => void;
  nextTitle?: string;
  nextType?: 'button' | 'submit' | 'reset';
  onGoNext?: () => void;
  hasBack?: boolean;
  hasNext?: boolean;
  isLoadingNext?: boolean;
}

export const BottomFormNavigation: FC<GroupsFormNavigationProps> = ({
  backTitle,
  backType = 'button',
  onGoBack,
  nextTitle,
  onGoNext = () => ({}),
  nextType = 'button',
  hasBack,
  hasNext = true,
  isLoadingNext = false,
}) => {
  const { t } = useTranslation();

  const labelBack = backTitle ?? t('common.cancel');
  const labelNext = nextTitle ?? t('common.save');

  const handleGoBack = () => {
    if (onGoBack) onGoBack();

    return;
  };

  return (
    <Box
      bottom={0}
      left={0}
      paddingX={8}
      paddingY={2}
      position="fixed"
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        boxShadow: '1px 0px 3px rgba(16, 24, 40, 0.1), 1px 0px 2px -1px rgba(16, 24, 40, 0.1)',
        zIndex: theme.zIndex.modal,
      })}
      width="100%"
    >
      <Box display="flex" gap={2} justifyContent="flex-end">
        {(hasBack || onGoBack) && (
          <Button
            color="secondary"
            data-testid={'button-go-back'}
            onClick={handleGoBack}
            size="large"
            sx={(theme) => ({
              minWidth: theme.spacing(18),
            })}
            type={backType}
            variant="outlined"
          >
            {labelBack}
          </Button>
        )}
        {hasNext && (
          <LoadingButton
            data-testid={'button-submit'}
            loading={isLoadingNext}
            onClick={onGoNext}
            size="large"
            sx={(theme) => ({
              minWidth: theme.spacing(18),
            })}
            type={nextType}
            variant="contained"
          >
            {labelNext}
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
};
