import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useOrganizationByIdApi } from '@hooks/api/organizations/useOrganizationsApi';

export const useFormOrganizationState = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const { data: organizationData, isLoading } = useOrganizationByIdApi({ organizationId: id });

  const organization = useMemo(() => {
    if (state) {
      return state.organization;
    }

    return organizationData;
  }, [state, organizationData]);

  return {
    organization,
    isLoading: !organization && isLoading,
  };
};
