import { useCallback } from 'react';

import { useJsonFetch } from '@hooks/api/useJsonFetch';
import { useEnvironment } from '@hooks/useEnvironment';

export const useCloseSMANumberApi = (projectId?: string) => {
  const { apiUrl } = useEnvironment();
  const jsonFetch = useJsonFetch(true, apiUrl);

  const fetcher = useCallback(
    (orderNumberId: string) =>
      jsonFetch(
        `/api/organization-management/v1/projects/${projectId}/ordernumbers/${orderNumberId}/close`,
        {
          method: 'POST',
        },
      ),
    [jsonFetch, projectId],
  );

  return fetcher;
};
