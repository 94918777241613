import { useAuth0 } from '@auth0/auth0-react';

import type { User } from '@hooks/api/users';

const buildUserId = (user: User): string => `${user.connection}|${user.id}`;

export interface IsLoggedInUserHook {
  isLoggedInUser: (user?: User | null) => boolean;
  isDeepupUser: boolean;
}

export const useIsLoggedInUser = (): IsLoggedInUserHook => {
  const { user: loggedInUser } = useAuth0();
  const userDomain = loggedInUser?.email?.split('@')[1]?.toLowerCase() ?? '';

  return {
    isLoggedInUser: (user) => !!user && buildUserId(user) === loggedInUser?.sub,
    isDeepupUser: userDomain.startsWith('deepup') ?? false,
  };
};
