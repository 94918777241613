import useSWR from 'swr';

import type { Project } from '@hooks/api/projects/types';
import { type PaginatedResponse, useJsonFetch } from '@hooks/api/useJsonFetch';

export const useProjectsApi = () => {
  const jsonFetch = useJsonFetch<PaginatedResponse<Project>>();
  const response = useSWR('/projects?size=1000', jsonFetch);

  return response;
};

export const useProjectByIdApi = ({ projectId }: { projectId?: string }) => {
  const jsonFetch = useJsonFetch<Project>();

  return useSWR(`/projects/${projectId}`, jsonFetch);
};
