import Box from '@mui/material/Box';
import type { FC } from 'react';
import type { DeepRequired, FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormInput, FormSectionHeader } from '@components/FormElements';
import type { Project } from '@hooks/api/projects';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';

import { ProjectMappingApiExport } from './ProjectMappingApiExport';

type ProjectsFormMasterDataProps = {
  register: UseFormRegister<Partial<Project>>;
  formErrors: FieldErrorsImpl<DeepRequired<Project>>;
  project?: Project;
};

export const ProjectsFormMasterData: FC<ProjectsFormMasterDataProps> = ({
  formErrors,
  register,
  project,
}) => {
  const { t } = useTranslation();
  const { isItemEnabled } = useRenderConfig();

  const isFormEnabled = isItemEnabled('projects.edit.form.mode');

  return (
    <Box marginBottom={2}>
      <FormSectionHeader title={t('common.masterData')} />
      <Box display="flex" flexDirection="column">
        <FormInput<Partial<Project>>
          defaultValue={project?.name}
          disabled={!isFormEnabled}
          error={formErrors.name}
          formFieldName="name"
          isRequired
          label={t('entities.projects.fields.name.label')}
          register={register}
        />
        <FormInput<Partial<Project>>
          defaultValue={project?.utmZone}
          disabled={!isFormEnabled}
          error={formErrors.utmZone}
          formFieldName="utmZone"
          helperText={t('entities.projects.fields.utmZone.placeholder')}
          isRequired
          label={t('entities.projects.fields.utmZone.label')}
          register={register}
        />
        <FormInput<Partial<Project>>
          defaultValue={project?.multipolygonGeoJson}
          disabled={!isFormEnabled}
          error={formErrors.multipolygonGeoJson}
          formFieldName="multipolygonGeoJson"
          helperText={t('entities.projects.fields.multipolygonGeoJson.placeholder')}
          label={t('entities.projects.fields.multipolygonGeoJson.label')}
          register={register}
        />
        <FormInput<Partial<Project>>
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={project?.period?.startDate?.split('T')[0]}
          disabled={!isFormEnabled}
          error={
            (formErrors.period as Record<string, FieldErrorsImpl<Partial<Project>>>)?.startDate
          }
          formFieldName="period.startDate"
          label={t('entities.projects.fields.startDate.label')}
          register={register}
          type="date"
        />

        <FormInput<Partial<Project>>
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={project?.period?.endDate?.split('T')[0]}
          disabled={!isFormEnabled}
          error={(formErrors.period as Record<string, FieldErrorsImpl<Partial<Project>>>)?.endDate}
          formFieldName="period.endDate"
          label={t('entities.projects.fields.endDate.label')}
          register={register}
          type="date"
        />
      </Box>

      <ProjectMappingApiExport disabled={!isFormEnabled} project={project} register={register} />
    </Box>
  );
};
