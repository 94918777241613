import { RpcRestError } from '@deepup/api-utils';
import {
  commonApiV1,
  billingApiV1 as billingApi,
  billingClientApiV1 as billingClientApi,
} from '@deepup/apis';
import useSWR from 'swr';

import { useGrpcRestTransport } from '@hooks/api/useGrpcTransport';

export const useApiGetScannerRent = ({
  organizationId,
  listOptions,
  dateRange,
}: {
  organizationId: string | null;
  dateRange: {
    start: string;
    end: string;
  };
  listOptions: {
    pageSize: number;
    page: number;
  };
}) => {
  const transport = useGrpcRestTransport();

  const key = [
    'billing',
    'scannerRent',
    organizationId,
    listOptions.page,
    listOptions.pageSize,
    dateRange.start,
    dateRange.end,
  ].join('-');

  const timeRange = commonApiV1.TimeRange.fromJson({
    start: dateRange.start,
    end: dateRange.end,
  });

  return useSWR<billingApi.GetScannerRentResponse, RpcRestError>(key, () => {
    const client = new billingClientApi.BillingServiceClient(transport);

    return client.getScannerRent({
      organizationId: organizationId ?? '',
      timeRange,
      options: {
        pageSize: listOptions.pageSize,
        page: BigInt(listOptions.page),
        contextId: '',
        withPaginationInfo: true,
      },
    }).response;
  });
};
