import type { RpcRestError } from '@deepup/api-utils';
import { useEffect } from 'react';

export const useErrorHandler = ({
  errors,
  defaultMessage,
  onError,
}: {
  onError: (message: string) => void;
  defaultMessage: string;
  errors: (RpcRestError | undefined)[];
}) => {
  useEffect(() => {
    const activeError = errors.find((error) => error !== null && error !== undefined);

    if (activeError) {
      const errorMessage = activeError.body?.message ?? defaultMessage;

      onError(errorMessage);
    }
  }, [defaultMessage, errors, onError]);
};
