import { Alert } from '@mui/material';
import type { CustomContentProps } from 'notistack';
import { forwardRef } from 'react';

type NotificationProps = CustomContentProps;

export const SnackbarNotification = forwardRef(
  (props: NotificationProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { variant, message } = props;
    const validatedVariant = variant === 'default' ? 'info' : variant;
    const validatedMessage = String(message);

    return (
      <Alert ref={ref} severity={validatedVariant} sx={{ whiteSpace: 'pre-line' }}>
        {validatedMessage}
      </Alert>
    );
  },
);

SnackbarNotification.displayName = 'SnackbarNotification';
