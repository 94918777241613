import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { getSelectValue } from '@components/FormElements';
import { useProjectUpdateApi, type Project } from '@hooks/api/projects';
import { revalidateCacheForEntity } from '@hooks/api/utils/revalidateCacheForEntity';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { useFormProjectState } from '@pages/Projects/useFormProjectState';

import { mapPeriod, ProjectsForm } from '../ProjectsForm';
import { patchNetworkDesign } from '../utils';

export const ProjectsEditOverview: FC = () => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbarMessage();
  const navigate = useNavigate();
  const { id } = useParams();
  const updateProject = useProjectUpdateApi();
  const { project } = useFormProjectState();

  const handleGoBackToProjects = () => {
    revalidateCacheForEntity('/projects');
    navigate('/projects');
  };

  const handleSubmitForm = async (formData: Partial<Project>) => {
    if (project) {
      try {
        const data = {
          ...formData,
          customerContactPerson: formData.customerContactPerson?.userId
            ? formData.customerContactPerson
            : undefined,
          networkDesign: patchNetworkDesign(formData.networkDesign),
        };

        const response = await updateProject({
          ...(data as Project),
          networkProviderId: getSelectValue(data.networkProviderId),
          generalContractorId: getSelectValue(data.generalContractorId),
          period: mapPeriod(data.period),
          id: project.id,
          version: project.version,
          apiExport: data.apiExport,
        });

        showMessage({
          message: t('pages.projects.edit.messages.updateSuccess', { projectName: response.name }),
          type: 'success',
        });
        handleGoBackToProjects();
      } catch (error) {
        showMessage({ message: (error as Error).toString(), type: 'error' });
      }
    } else {
      console.warn('no project given to update');
    }
  };

  if (!id || !project) {
    return null;
  }

  return <ProjectsForm onSubmit={handleSubmitForm} />;
};
