import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useProjectByIdApi } from '@hooks/api/projects/useProjectsApi';

export const useFormProjectState = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const { data: projectsData, isLoading } = useProjectByIdApi({ projectId: id });

  const project = useMemo(() => {
    if (state) return state.project;

    return projectsData;
  }, [projectsData, state]);

  return {
    project,
    isLoading: !project && isLoading,
  };
};
