import { XOutline } from '@deepup/icons';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, type GridColDef } from '@mui/x-data-grid';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { renderCellContent } from '@components/TableCell/TableCell';
import type { Project } from '@hooks/api/projects';

export type PermissionTypes = 'hasMonitoring' | 'hasDocumentation' | 'backofficeRole';

interface ProjectsOfApiAccessTableProps {
  entities: Project[];
  onDeleteProject: (projectId: string) => void;
  onDeleteAllProjects: () => void;
  isLoading: boolean;
}

export const ProjectsOfApiAccessTable: FC<ProjectsOfApiAccessTableProps> = ({
  entities,
  isLoading,
  onDeleteAllProjects,
  onDeleteProject,
}) => {
  const { t } = useTranslation();

  const handleRemoveProject = (id: string) => () => {
    onDeleteProject(id);
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 1,
      headerName: 'Name',
      renderCell: (params) =>
        renderCellContent({
          columnField: 'name',
          entityType: 'projects-of-api',
          isLoading,
          params,
          renderContent: (value) => (
            <Box alignItems="center" display="flex" height="100%">
              {value}
            </Box>
          ),
        }),
    },
    {
      field: 'context-menu',
      flex: 1,
      renderHeader: () => (
        <Box
          alignItems="center"
          display="flex"
          height="100%"
          justifyContent="flex-end"
          width="100vw"
        >
          <Button
            color="secondary"
            disabled={entities.length === 0}
            onClick={onDeleteAllProjects}
            startIcon={<XOutline />}
            variant="text"
          >
            {t('pages.apiAccesses.form.projects.components.btnRemoveAllProjects')}
          </Button>
        </Box>
      ),
      renderCell: (params) => (
        <Box
          alignItems="center"
          display="flex"
          height="100%"
          justifyContent="flex-end"
          width="100%"
        >
          <Button
            color="secondary"
            data-testid={`remove-project-${params.row.name}`}
            onClick={handleRemoveProject(params.row.id)}
            startIcon={<XOutline />}
            variant="text"
          >
            {t('pages.apiAccesses.form.projects.components.btnRemoveOneProject')}
          </Button>
        </Box>
      ),
      sortable: false,
    },
  ];

  return (
    <DataGrid<Project>
      columns={columns}
      disableColumnMenu
      disableRowSelectionOnClick
      initialState={{
        columns: {
          columnVisibilityModel: {
            contextMenu: entities.length !== 0,
          },
        },
      }}
      loading={isLoading}
      rows={entities}
      slotProps={{
        loadingOverlay: {
          variant: 'skeleton',
          noRowsVariant: 'skeleton',
        },
      }}
    />
  );
};
