import { useMemo } from 'react';

import { useOrganizationsApi, type Organization } from '../organizations';

export const useMapOrganizationsToEntities = <TEntity, TMapping>({
  entities,
  isDeepUpOrg,
  mapping,
}: {
  entities?: TEntity[];
  isDeepUpOrg?: boolean;
  mapping: (entity: TEntity, getOrgById: (key: string) => Organization | undefined) => TMapping;
}) => {
  const {
    data: organizationsData,
    isLoading: isLoadingOrganizations,
    error: organizationsError,
  } = useOrganizationsApi({ isDeepUpOrg });

  const organizations = useMemo(() => {
    const map = new Map<string, Organization>();

    organizationsData?.content?.forEach((item) => {
      map.set(item.id, item);
    });

    return map;
  }, [organizationsData?.content]);

  const entitiesWithOrganization = useMemo(() => {
    if (!entities) {
      return [];
    }

    return entities.map((entity) => {
      return mapping(entity, (key: string) => {
        return organizations.get(key);
      });
    });
  }, [entities, mapping, organizations]);

  return {
    entitiesWithOrganization,
    isLoadingOrganizations,
    organizationsError,
  };
};
