import { type FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyTableInfo } from '@components/PageEntityTable/EmptyTableInfo';
import useDebounce from '@hooks/api/useDebounce';
import { useUsersPaginatedApi } from '@hooks/api/users/useUsersPaginatedApi';
import { useUsersUrlSync } from '@hooks/api/users/useUsersUrlSync';
import { useMapOrganizationsToEntities } from '@hooks/api/utils';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';
import { useSnackbarMessage } from '@hooks/useSnackbarMessage';
import { OverviewPageLayout } from '@pages/common';

import { UserFilterBar } from './UserFilterBar';
import { UserManagementTable } from './UserManagementTable';

export const UserManagementOverview: FC = () => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbarMessage();

  const { isItemVisible } = useRenderConfig();

  const {
    page,
    size,
    sortModel,
    nameOrEmail,
    organizationId,
    handlePageChange,
    handleSizeChange,
    handleSortModelChange,
  } = useUsersUrlSync();

  const debouncedNameOrEmail = useDebounce(nameOrEmail);

  const {
    data: usersData,
    error: usersError,
    isLoading: isLoadingUsers,
  } = useUsersPaginatedApi({
    page,
    size,
    sortModel,
    nameOrEmail: debouncedNameOrEmail,
    organizationId,
  });

  useEffect(() => {
    if (usersError) {
      console.error('Failed to fetch users:', usersError);
      showMessage({ message: t('common.noItemFound'), type: 'error' });
    }
  }, [t, showMessage, usersError]);

  const { entitiesWithOrganization: usersWithOrganizations, isLoadingOrganizations } =
    useMapOrganizationsToEntities({
      entities: usersData?.content,
      mapping: (user, getOrgById) => ({
        ...user,
        organizationName: getOrgById(user.organizationId)?.name,
      }),
    });

  const rowCount = usersData?.totalElements ?? -1;
  const hasCreateButton = isItemVisible('user.overview.components.btnCreate');
  const hasUsers = usersWithOrganizations.length > 0;

  const isLoading = isLoadingOrganizations || isLoadingUsers;

  if (usersError) return <div>failed to load</div>;

  return (
    <OverviewPageLayout
      createRoute={hasCreateButton ? '/users/create' : ''}
      createTitle={t('pages.user.overview.components.btnCreate')}
      title={t('pages.user.overview.title')}
    >
      <UserFilterBar />
      {!hasUsers && !isLoading && (
        <EmptyTableInfo info={t('pages.user.overview.components.table.emptyInfo')} />
      )}
      {(hasUsers || isLoading) && (
        <UserManagementTable
          isLoading={isLoading}
          nameOrEmail={debouncedNameOrEmail}
          onPageChange={handlePageChange}
          onSizeChange={handleSizeChange}
          onSortModelChange={handleSortModelChange}
          organization={organizationId}
          page={page}
          rowCount={rowCount}
          size={size}
          sortModel={sortModel}
          users={usersWithOrganizations}
        />
      )}
    </OverviewPageLayout>
  );
};
