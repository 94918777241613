import { useSnackbar, type SnackbarOrigin, type VariantType } from 'notistack';
import { useCallback } from 'react';

export const useSnackbarMessage = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showMessage = useCallback(
    ({
      message,
      type,
      anchorOrigin,
      autoHideDuration = 5000,
    }: {
      message: string;
      type: VariantType;
      anchorOrigin?: SnackbarOrigin;
      autoHideDuration?: number;
    }) => {
      enqueueSnackbar(message, {
        autoHideDuration,
        variant: type,
        anchorOrigin,
      });
    },
    [enqueueSnackbar],
  );

  return {
    showMessage,
  };
};
