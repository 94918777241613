import type { OrderNumber } from '@deepup/apis/dist/typescript/organization-management/v1/organization_management';
import { useMemo, useState } from 'react';

export type Status = 'all' | 'open' | 'closed';

export const useSMANumberFilters = (entities: OrderNumber[] | undefined) => {
  const [orderNumberFilter, setOrderNumberFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState<Status>('all');

  const filteredEntities = useMemo(() => {
    if (!entities) return [];

    return entities.filter((entity) => {
      const hasMatchingOrderNumber = entity.orderNumber
        .toLowerCase()
        .includes(orderNumberFilter.toLowerCase());
      const isOpen = !entity.closedAt;

      if (statusFilter === 'open') return hasMatchingOrderNumber && isOpen;
      if (statusFilter === 'closed') return hasMatchingOrderNumber && !isOpen;

      return hasMatchingOrderNumber;
    });
  }, [entities, orderNumberFilter, statusFilter]);

  return {
    orderNumberFilter,
    setOrderNumberFilter,
    statusFilter,
    setStatusFilter,
    filteredEntities,
  };
};
