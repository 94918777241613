import { DateTime } from 'luxon';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { SelectItem } from '@components/FormElements';
import { useProjectsApi } from '@hooks/api/projects';
import type { Project } from '@hooks/api/projects/types';

export const useProjects = (id: string) => {
  const [selectedProjects, setSelectedProjects] = useState<SelectItem[] | null>(null);

  const { data: projectsData } = useProjectsApi();
  const orgProjects = useMemo(() => {
    const projectList = projectsData?.content || ([] as Project[]);

    return projectList
      .filter((item) => item.organizationId === id)
      .map((item) => ({
        id: item.id,
        name: item.name,
      }));
  }, [projectsData, id]);

  return {
    setSelectedProjects,
    selectedProjects,
    projectList: orgProjects,
  };
};

function getUTCMonthStartAndEnd(date: Date, language: string) {
  const luxonDate = DateTime.fromJSDate(date, { zone: 'utc' });

  const startOfMonth = luxonDate.startOf('month').toJSDate().toISOString();
  const endOfMonth = luxonDate.endOf('month').toJSDate().toISOString();
  const name = luxonDate.toFormat('LLLL/yyyy', { locale: language });

  return { startOfMonth, endOfMonth, name };
}

const generateMonths = (language: string) => {
  // invoiced labeling data was introduced at Deepup in April 2024
  const startMonth = new Date(2024, 4);
  const currentMonth = new Date();

  // Add one month to the current month to include it in the list
  currentMonth.setMonth(currentMonth.getMonth() + 1);
  const months = [];

  for (let date = startMonth; date <= currentMonth; date.setMonth(date.getMonth() + 1)) {
    const { startOfMonth, endOfMonth, name } = getUTCMonthStartAndEnd(date, language);

    months.unshift({
      id: `${startOfMonth}_${endOfMonth}`,
      name,
    });
  }

  return months;
};

export const useMonths = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const monthList = generateMonths(language);
  const [month, setMonth] = useState<SelectItem>(monthList[0]);

  return {
    month,
    setMonth: (id: string) => {
      const nextMonth = monthList.find((item) => item.id === id) || monthList[0];

      setMonth(nextMonth);
    },
    monthList,
  };
};
