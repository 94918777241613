import { useApiGetScannerRent } from './useApiGetScannerRent';
import { getDateRangeFromMonthId } from './useInvoicedMetersData';

export const useScannerRentData = ({
  organizationId,
  month,
  paginationModel,
}: {
  organizationId: string | null;
  month: { id: string };
  paginationModel: { page: number; pageSize: number };
}) => {
  const dateRange = getDateRangeFromMonthId(month.id);

  return useApiGetScannerRent({
    organizationId,
    dateRange,
    listOptions: paginationModel,
  });
};
