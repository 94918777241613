import { GrpcRestFetchTransport, useAuth0Interceptor } from '@deepup/api-utils';
import { useMemo, useContext, createContext } from 'react';

import { useEnvironment } from '@hooks/useEnvironment';

const GrpcRestTransportContext = createContext<GrpcRestFetchTransport | null>(null);

type Props = { children: React.ReactNode };

export const GrpcRestTransportProvider = ({ children }: Props) => {
  const { apiUrl } = useEnvironment();
  const authInterceptor = useAuth0Interceptor();

  const transportRest = useMemo(() => {
    return new GrpcRestFetchTransport({
      baseUrl: apiUrl,
      fetchInit: {
        credentials: 'include',
      },
      interceptors: [authInterceptor],
    });
  }, [authInterceptor, apiUrl]);

  return (
    <GrpcRestTransportContext.Provider value={transportRest}>
      {children}
    </GrpcRestTransportContext.Provider>
  );
};

export const useGrpcRestTransport = () => {
  const context = useContext(GrpcRestTransportContext);

  if (!context) {
    throw new Error('useGrpcRestTransport must be used within a GrpcRestTransportProvider');
  }

  return context;
};
