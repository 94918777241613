import type { OrderNumber } from '@deepup/apis/dist/typescript/organization-management/v1/organization_management';
import { useCallback } from 'react';
import useSWR from 'swr';

import { useEnvironment } from '@hooks/useEnvironment';

import { useJsonFetch, type GrpcPaginatedResponse } from '../useJsonFetch';

export const useSMANumbersApi = (projectId?: string) => {
  const { apiUrl } = useEnvironment();

  const jsonFetch = useJsonFetch<GrpcPaginatedResponse<OrderNumber>>(true, apiUrl);

  const fetcher = useCallback(
    () =>
      jsonFetch(`/api/organization-management/v1/projects/${projectId}/ordernumbers`, {
        method: 'POST',

        body: JSON.stringify({
          options: {
            withPaginationInfo: true,
            page_size: 100000,
          },
        }),
      }),
    [jsonFetch, projectId],
  );

  return useSWR(`/api/organization-management/v1/projects/${projectId}/ordernumbers`, fetcher);
};
