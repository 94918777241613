import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyTableInfo } from '@components/PageEntityTable/EmptyTableInfo';
import { useOrganizationsPaginatedApi } from '@hooks/api/organizations/useOrganizationsPaginatedApi';
import { useOrganizationsUrlSync } from '@hooks/api/organizations/useOrganizationsUrlSync';
import useDebounce from '@hooks/api/useDebounce';
import { useRenderConfig } from '@hooks/ui/useRenderConfig/useRenderConfig';
import { OverviewPageLayout } from '@pages/common';

import { OrganizationsTable } from './OrganizationsTable';

export const OrganizationsOverview: FC = () => {
  const { t } = useTranslation();
  const { isItemVisible } = useRenderConfig();

  const {
    page,
    size,
    sortModel,
    name,
    handlePageChange,
    handleSizeChange,
    handleSortModelChange,
    handleNameChange,
  } = useOrganizationsUrlSync();

  const debouncedName = useDebounce(name);

  const {
    data: organizationsData,
    error: organizationsDataError,
    isLoading: isLoadingOrganizations,
  } = useOrganizationsPaginatedApi({
    page,
    size,
    sortModel,
    name: debouncedName,
  });

  const organizations = organizationsData?.content ?? [];

  if (organizationsDataError) return <div>failed to load</div>;

  const rowCount = organizationsData?.totalElements ?? -1;
  const hasCreateButton = isItemVisible('organizations.overview.components.btnCreate');
  const hasOrganizations = organizations.length > 0;

  return (
    <OverviewPageLayout
      createRoute={hasCreateButton ? '/organizations/create' : ''}
      createTitle={t('pages.organization.overview.components.btnCreate.label')}
      title={t('pages.organization.overview.title')}
    >
      <Box display="flex" mb={4}>
        <Box flexBasis="50%">
          <TextField
            data-testid="search-field"
            fullWidth
            onChange={(event) => {
              const searchString = event.currentTarget.value;

              handleNameChange(searchString);
            }}
            placeholder={t('pages.organization.overview.components.filter.organization')}
            type="search"
            value={name}
          />
        </Box>
      </Box>
      {!isLoadingOrganizations && !hasOrganizations && (
        <EmptyTableInfo info={t('pages.organization.overview.components.table.emptyInfo')} />
      )}
      {(isLoadingOrganizations || hasOrganizations) && (
        <OrganizationsTable
          isLoading={isLoadingOrganizations}
          name={debouncedName}
          onPageChange={handlePageChange}
          onSizeChange={handleSizeChange}
          onSortModelChange={handleSortModelChange}
          organizations={organizations}
          page={page}
          rowCount={rowCount}
          size={size}
          sortModel={sortModel}
        />
      )}
    </OverviewPageLayout>
  );
};
