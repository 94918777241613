import { CircleInformationOutline } from '@deepup/icons';
import { Box, Checkbox, FormControlLabel, Stack, Tooltip, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import type { Path, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { Project } from '@hooks/api/projects';

export type ProjectMappingApiExportProps = {
  register: UseFormRegister<Partial<Project>>;
  project?: Project;
  disabled: boolean;
};

export const ProjectMappingApiExport = ({
  register,
  project,
  disabled,
}: ProjectMappingApiExportProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const iconSize = theme.spacing(3);

  const [hasAssets, setHasAssets] = useState<boolean>(project?.apiExport ?? false);

  useEffect(() => {
    if (project) setHasAssets(project.apiExport);
  }, [project]);

  return (
    <Stack mb={2}>
      <Stack alignItems="center" direction="row">
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              {...register('apiExport' as Path<Project>)}
              checked={hasAssets}
              onChange={(_, checked) => setHasAssets(checked)}
            />
          }
          label={t('pages.projects.create.uploadApiExport.toggleDescription')}
        />
        <Tooltip arrow title={t('pages.projects.create.uploadApiExport.toggleDescriptionTooltip')}>
          <Box alignItems="center" display="flex" sx={{ cursor: 'pointer' }}>
            <CircleInformationOutline height={iconSize} width={iconSize} />
          </Box>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
