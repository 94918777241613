import { Box, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { Status } from '../hooks/useSMANumberFilters';

interface Props {
  orderNumberFilter: string;
  statusFilter: Status;
  onOrderNumberChange: (value: string) => void;
  onStatusChange: (value: Status) => void;
  disabled?: boolean;
}

export const SMANumberFilters: FC<Props> = ({
  orderNumberFilter,
  statusFilter,
  onOrderNumberChange,
  onStatusChange,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" gap={2} mb={2} width={400}>
      <TextField
        disabled={disabled}
        fullWidth
        label={t('pages.smaNumbers.searchFilter.orderNumber')}
        onChange={(e) => onOrderNumberChange(e.target.value)}
        value={orderNumberFilter}
      />
      <FormControl disabled={disabled} size="medium" sx={{ minWidth: 150 }}>
        <InputLabel>Status</InputLabel>
        <Select
          label={t('pages.smaNumbers.searchFilter.status')}
          onChange={(e) => onStatusChange(e.target.value as Status)}
          value={statusFilter}
        >
          <MenuItem value="all">{t('pages.smaNumbers.status.all')}</MenuItem>
          <MenuItem value="open">{t('pages.smaNumbers.status.open')}</MenuItem>
          <MenuItem value="closed">{t('pages.smaNumbers.status.closed')}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
